<template>
    <div class="ssn">

        <div class="ssn__icon" :class="getSsnClass(ssn)">

            <img v-if="ssn.image" v-bind:src="ssn.image"/>
            <span v-else>{{getSsnInitials(ssn)}}</span>
            
            <div class="ssn__indicator">
            </div>

        </div>

        <p class="ssn__name">
            {{ssn.full_name}}
        </p>
    </div>
</template>

<script>
import Constants from '../Lib/Constants';
 
export default {
  name: 'SafelistIcon',
  
  props: ['ssn'],

  methods : {

        getSsnClass(ssn){

            if (ssn.response_type === Constants.responseType.canHelp)
                return 'ssn__icon--canHelp'
            else if (ssn.response_type === Constants.responseType.cannotHelp)
                return 'ssn__icon--cannotHelp'
            else return 'ssn__icon--unanswered'
        },

        getSsnInitials(ssn){
            const split = ssn.full_name.toUpperCase().split(' ');
            return split.length === 2 
                ? split[0][0]+split[1][0]
                : split[0][0]
        }
    }

}
</script>
