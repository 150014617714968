import store from '../store';
import * as types from '../store/mutation-types';

class geolocationManager{
    constructor(){
        this.lat = null;
        this.lon = null;

        if (navigator.geolocation){
             this.positionWatcher = navigator.geolocation.watchPosition( position => {

                const currLat = position.coords.latitude;
                const currLon = position.coords.longitude;

                if ( !(this.lat && this.lon) ){

                    this.lat = currLat;
                    this.lon = currLon
                }
                else {
                
                    if (this.lat !== currLat || this.lon !== currLon){
                        this.lat = currLat;
                        this.lon = currLon;
                    }
                    else return;
                }

                store.commit(types.updateSsnLocation, {lat: this.lat, lon: this.lon})

             }, err => console.error('The Geolocation service failed.', err))
        }
        else {
            console.error('Your browser doesn\'t support geolocation.');
        };
    }
}

export default new geolocationManager();