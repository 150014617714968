import * as types from '../mutation-types';

const state = {

    tracking_id:null,
    type: null,
    active: null,
    alarm_response_id: null,
    created_at: null,
    isLoading: null

}

const mutations = {

    [types.loadAlarmStarted] (state){
        state.isLoading = true;
    },

    [types.loadAlarmFailed] (state){
        state.isLoading = false;
    },

    [types.loadAlarmSucceed] (state, {alarm}){

        state.tracking_id = alarm.tracking_id;
        state.type = alarm.type;
        state.active = alarm.active;
        state.alarm_response_id = alarm.alarm_response_id;
        state.created_at = alarm.created_at;
        state.isLoading = false;

    },

    [types.receiveAlarmUpdate] (state, {alarm}){
        if (alarm) {
          state.active = alarm.active;
        } else {
          state.active = false;
        }
    }
}

const actions = {}
const getters = {
    getAlarm: state => state
}

export default {
    state,
    mutations,
    actions,
    getters
}
