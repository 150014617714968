<template>
    <div class="respond">

        <img class="respond__logo" src="../../assets/img/katana_logo.png"/>

        <div  v-if="alarm != undefined && alarm.active === 1" class="respond__container">

            <h2 class="respond__heading">
                {{user.full_name}}<br/>has sent an alert to you!
            </h2>

            <p class="respond__time"> {{ formattedAlarmCreated }}</p>

            <div v-if="targetSsn" class="respond__buttons-container">

                    <button key="canHelp"
                        v-if="targetSsn.response_type === responseDidNotRespond
                            || targetSsn.response_type === responseCannotHelp"
                        v-on:click="checkSendResponse(targetSsn, responseCanHelp)"
                        class="respond__button respond__button--can-help">
                            I can help
                    </button>

                    <button key="joinCall"
                        v-if="targetSsn.response_type === responseCanHelp"
                        v-on:click="startCall"
                        class="respond__button respond__button--join-call"
                        :class="getCallButtonClass()">
                            Join call
                    </button>

                    <button key="cantHelp"
                        class="respond__button respond__button--cant-help"
                        v-on:click="checkSendResponse(targetSsn, responseCannotHelp)"
                        :class="getCantHelpClass(targetSsn)">
                            I cannot help
                    </button>

            </div>

            <div class="respond__call-notifications">
                <p v-if="conferenceStatus">Conference Call is in progress</p>
                <p v-if="isCalling">Your phone is about to ring...answer it. You are joining a call with the people below to offer help.</p>
            </div>

            <p v-if="targetSsn &&
                targetSsn.response_type == 1" class="respond__distance">
                You must be within 10 miles of the person to be seen on the map.
            </p>

        </div>

        <div  v-if="alarm == undefined || alarm.active === 0" class="respond__container">

            <h2 class="respond__heading">
                {{user.full_name}}<br/>has canceled the alert.
            </h2>

            <h1>
                Alert is no longer active.
            </h1>

        </div>


    </div>
</template>

<script>
import Constants from '../Lib/Constants';
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {

    name: 'Respond',
    data() {
        return {
            responseCanHelp : Constants.responseType.canHelp,
            responseCannotHelp: Constants.responseType.cannotHelp,
            responseDidNotRespond: Constants.responseType.didNotRespond
        }
    },
    computed: {
        ...mapGetters({
            targetSsn: 'getTargetSsn',
            callStatus: 'getCallStatus',
            conferenceStatus: 'getConferenceStatus',
            isCalling: 'getIsCalling',
            readyToCall: 'getReadyToCall',
            alarm: 'getAlarm',
            user: 'getUser'
        }),
        formattedAlarmCreated: function(){ return moment(this.alarm.created_at).format('dddd, MMMM D, YYYY. \u2022 hh:mm A')}

    },
    methods:{
        ...mapActions({
            sendResponse: 'sendResponse',
            startCall: 'startCall'
        }),
        checkSendResponse(ssn, response){
            if (ssn.response_type !== response) this.sendResponse(response)
        },
        getCantHelpClass(ssn){
            if (ssn)
                return ssn.response_type === this.responseCannotHelp ? 'cant-help-active' : ''
        },
        getCallButtonClass(){
            return this.readyToCall ? '' : 'disabled'
        }
    }

}

</script>
