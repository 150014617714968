import * as firebase from 'firebase';

import Config from '../Lib/Config';
import Utils from '../Lib/Utils';
import Constants from '../Lib/Constants';
import APIClient from '../Lib/APIClient';

import store from '../store';
import * as types from '../store/mutation-types';

class callManager{
    constructor(){

        this.room_id = Utils.getParameterByName('alarm_id');
        this.targetSsnId = Utils.getParameterByName('receive_safe_list_user_id');
        this.checkCallInterval = null;
        this.checkConferenceInterval = null;

        store.subscribe( mutation => {

            if(mutation.type === types.loadUserSucceed) {
                this.ssn_mobile_number = mutation.payload.user.safe_list[this.targetSsnId].mobile_number;
                this.user_name = mutation.payload.user.full_name;
            }

            if (mutation.type === types.startCall) {
                this.makeCall();
            }

            if (mutation.type === types.sendResponse){
                if (mutation.payload.response === Constants.responseType.cannotHelp){
                    if (this.checkCallInterval);
                    clearInterval(this.checkCallInterval);
                }
            }

        });

        this.checkConference();
        this.checkConferenceInterval = setInterval(()=> this.checkConference(), 5000);
    }

    makeCall(){

        if (!(this.ssn_mobile_number && this.user_name)) return

        else{
            const params = {
                room_id: this.room_id,
                mobile_number: this.ssn_mobile_number,
                user_name: this.user_name
            }

            APIClient.post('/Conference/makeCall', params, response => {
                if (response.error_data) {
                    store.commit(types.callFailed);
                    return console.log("Response error:", response.error_data);
                }

                const callId = response.call_id;

                this.checkCallInterval = setInterval(() => {
                    this.checkCall(callId);
                }, 5000);

                localStorage.setItem("callId:" + this.room_id + "-" + this.targetSsnId, callId);

            },
            () => {
                store.commit(types.callFailed);
                clearInterval(this.checkCallInterval);
                console.error("Call failed");
            })
        }
    }
    checkCall(callId){

        const params = {
            call_id : callId
        };

        APIClient.get("/Conference/checkCall", params, (response) => {

            if (response.error_data)
                return console.error("Response error:", response.error_data);

            const callData = response.call;

            if (!callData) {
                store.commit(types.checkCall, false);
                clearInterval(this.checkCallInterval);
            }
            else {

                switch (callData.status) {
                    case Constants.callStatus.inProgress:
                        store.commit(types.checkCall, true);

                        break;
                    case Constants.callStatus.busy:
                    case Constants.callStatus.completed:
                    case Constants.callStatus.failed:
                    case Constants.callStatus.noAnswer:
                    case Constants.callStatus.canceled:

                        store.commit(types.checkCall, false);
                        clearInterval(this.checkCallInterval);

                };

                console.log("Check call success:", callData.status);

            }

        }, () => {

            console.error("Check call failed");

        });
    }
    checkConference(){
        const params = {
            room_id: this.room_id
        };

        APIClient.get("/Conference/checkConference", params, response => {

            if (response.error_data)
                return console.error("Response error:", response.error_data);

            response.in_progress
            ? store.commit(types.checkConference, true)
            : store.commit(types.checkConference, false);

        }, () => console.error("Check conference failed"))

    }

}

export default new callManager();
