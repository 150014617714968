import * as types from '../mutation-types';
import constant from '../../Lib/Constants';
import Utils from '../../Lib/Utils';
import Constants from '../../Lib/Constants';
import APIClient from '../../Lib/APIClient';

const state = {

    safelist: [],
    targetSsnId: Utils.getParameterByName('receive_safe_list_user_id')

}

const mutations = {

    [types.loadUserSucceed](state, { user }) {

        const safelist = user.safe_list.map((ssn, id) => ({

            id,
            location: ssn.location,
            full_name: ssn.full_name,
            response_type: constant.responseType.didNotRespond,
            is_tracking: ssn.is_tracking,
            image: ssn.image || null

        }));

        state.safelist = safelist;

    },

    [types.receiveResponseUpdate](state, { responses }) {

        responses.forEach(res => {
            const id = res.safe_list_user_id
            let ssn = state.safelist.find(ssn => (ssn != undefined && ssn.id === id))
            if (ssn != undefined){
              ssn.response_type = res.response_type;
            }
        })

    },

    [types.sendResponse](state, { response }) {
        state.safelist.find(ssn =>(ssn != undefined &&  ssn.id == state.targetSsnId)).response_type = response
    },

    [types.receiveSsnUpdate](state, { safe_list }) {

        if (state.safelist.length > 0) {

            const newSafelist = safe_list.map((ssn, i) => ({ ...state.safelist[i], ...{ location: ssn.location } }))

            state.safelist = newSafelist;
        }

    },

    [types.updateSsnLocation](state, payload) { }

}

const actions = {
    sendResponse({ state, commit, rootState }, response) {

        commit({
            type: types.sendResponse,
            response
        });

        const params = {
            user_id: rootState.user.user_id,
            ssn_name: state.safelist[state.targetSsnId].full_name
        }

        if (response === Constants.responseType.canHelp)
            APIClient.post("/User/sendCanHelpPushNotification", params,
                response => {
                    if (response.error_data)
                        return console.error("Response error:", response.error_data);

                    console.log("Send push notification success");
                },
                () => console.error('Send push notification failed'))
        else if (response === Constants.responseType.cannotHelp)
            APIClient.post("/User/sendCannotHelpPushNotification", params,
                response => {
                    if (response.error_data)
                        return console.error("Response error:", response.error_data);

                    console.log("Send push notification success");
                },
                () => console.error('Send push notification failed'))
    }
}

const getters = {

    getTargetSsn: state => {

        if (state.targetSsnId && state.safelist.length > 0) {
            return state.safelist.find(ssn => (ssn != undefined && ssn.id == state.targetSsnId))
        }
        else return null
    },
    getSafelistAnswered: state => state.safelist.filter(ssn => ssn.response_type !== Constants.responseType.didNotRespond),
    getSafelistUnanswered: state => state.safelist.filter(ssn => ssn.response_type === Constants.responseType.didNotRespond),
    getSafelistAnsweredCanHelp: (state, getters) => getters.getSafelistAnswered.filter(ssn => ssn.response_type === Constants.responseType.canHelp),
    getSafelistAnsweredCannotHelp: (state, getters) => getters.getSafelistAnswered.filter(ssn => ssn.response_type === Constants.responseType.cannotHelp)
}

export default {
    state,
    mutations,
    actions,
    getters
}
