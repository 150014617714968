//alarm
export const loadAlarmStarted = 'loadAlarmStarted';
export const loadAlarmSucceed = 'loadAlarmSucceed';
export const loadAlarmFailed = 'loadAlarmFailed';
export const receiveAlarmUpdate = 'receiveAlarmUpdate';

//user
export const loadUserStarted = 'loadUserStarted';
export const loadUserSucceed = 'loadUserSucceed';
export const loadUserFailed = 'loadUserFailed';

//tracking
export const receiveUserTracking = 'receiveUserTracking';
export const receiveSsnUpdate = 'receiveSsnUpdate';
export const updateSsnLocation = 'updateSsnLocation';
export const updateUserHomeDistance = 'updateUserHomeDistance';

//response
export const receiveResponseUpdate = 'receiveResponseUpdate';
export const sendResponse = 'sendResponse';

//call
export const checkCall = 'checkCall';
export const checkConference = 'checkConference';
export const startCall = 'startCall';
export const callSucceed = 'callSucceed';
export const callFailed = 'callFailed';
