const Constants = {};

Constants.responseStatus = {
	failed: 0,
    sentSms: 1,
    sentMail: 2,
    sentBoth: 3
};

Constants.responseType = {
	didNotRespond: 0,
	canHelp: 1,
	cannotHelp: 2
};

Constants.alarmType = {
	silent: 1,
	panic: 2
};

// observer
Constants.onCanHelp = "onCanHelp";
Constants.onCannotHelp = "onCannotHelp";
Constants.onAlarmInactive = "onAlarmInactive";
Constants.onChangeMarkerVisibility = "onChangeMarkerVisibility";

Constants.markerIcons = {
    user: '/assets/img/marker_alert_full.png',
    ssn: '/assets/img/marker_safe_list_full.png'
}

Constants.callStatus = {
	queued: "queued",
	ringing: "ringing",
	inProgress: "in-progress",
	completed: "completed",
	busy: "busy",
	failed: "failed",
	noAnswer: "no-answer",
	canceled: "canceled"
};

// module.exports = Constants;
export default Constants;