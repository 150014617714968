

var Config = {};

Config.firebase = {
	apiKey: "AIzaSyD9x7_3j6-BldzGOcx-dGbaG7cu3DTYdsg",
	authDomain: "aluma-development.firebaseapp.com",
	databaseURL: "https://aluma-development.firebaseio.com/",
	storageBucket: "aluma-development.appspot.com",
	projectId: "aluma-development",
	messagingSenderId: "619990209966"
}

module.exports = Config;
