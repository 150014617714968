
var _ = require('lodash');

(function() {
    "use strict";

    function Utils() {

    }

    // public
    Utils.prototype.getRandomString = getRandomString;
    Utils.prototype.assignDelay = assignDelay;
    Utils.prototype.getParameterByName = getParameterByName;
    Utils.prototype.getMiles = getMiles;

    // implementations
    function getRandomString(length){
    
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        
        if(!length){
            length = 32;
        }
        
        for( var i=0; i < length; i++ )
            text += possible.charAt(Math.floor(Math.random() * possible.length));
    
        return text;
    }


    function assignDelay(variable,value){
        
    }

    function getParameterByName(name, url) {
        if (!url) {
          url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    function getMiles(meters) {
        return meters * 0.000621371192;
    }

    // Exports
    module.exports = new Utils(); // singleton
 
})();
