<template>
    <div class="app__wrapper">
        
        <Map/>

        <div class="app__left">
            <Respond/>
            <Safelist/>
        </div>

        <div class="app__right">
            <MapHeader/>

        </div>

    </div>

</template>

<script>
import Map from './components/Map.vue';
import MapHeader from './components/MapHeader.vue';
import Respond from './components/Respond.vue';
import Safelist from './components/Safelist.vue';

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'app',
  components: {
      Map,
      Respond,
      Safelist,
      MapHeader
  }

}
</script>

<style src="../css/main.scss" lang="scss">
</style>
