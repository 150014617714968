import * as types from '../mutation-types';

const state = {

    isConferenceInProgress: null,
    isCalling: null,
    isCallInProgress: null

}

const mutations = {
    [types.callFailed] (){
        state.isCalling = false;
        state.isCallInProgress = false;
    },
    
    [types.checkCall] (state, status){
        state.isCallInProgress = status;
        state.isCalling = false;
    },
    
    [types.checkConference] (state, status){
        state.isConferenceInProgress = status;
    },

    [types.startCall] (){
        state.isCalling= true;
        state.isCallInProgress = false;
    },
    
    [types.sendResponse] (state, {response}){
        state.isCalling = false;
        state.isCallInProgress = false;
    }
}

const actions = {
    startCall: ({state, commit, rootState}) => commit(types.startCall)
}
const getters = {
    getConferenceStatus : state => state.isConferenceInProgress,
    getCallStatus: state => state.isCallInProgress,
    getIsCalling: state => state.isCalling,
    getReadyToCall: state => (state.isConferenceInProgress || !(state.isCalling || state.isCallInProgress))
}

export default {
    state,
    mutations, 
    actions,
    getters
}
