<template>
    <div class="map__wrapper">
        <div ref="map" class="map">
        </div>
    </div>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex';
import Constants from '../Lib/Constants';
import MapHeader from './MapHeader.vue';
import Utils from '../Lib/Utils';
import * as types from '../store/mutation-types';

class CustomOverlay extends google.maps.OverlayView {

    constructor(map, position, content, isUser=false){
        super();

        this.setValues({
            position: new google.maps.LatLng(position),
            container: null,
            content: content,
            map: map
        });

        this.map = map;
        this.isUser = isUser;
        this.isVisible = true;
        this.title = content.title;
    }

    onAdd(){
        let container = document.createElement('div');
        container.classList.add('overlay-container');
        let marker = document.createElement('img');
        marker.classList.add('overlay-marker');

        if(this.isUser){
            container.classList.add('overlay-container--user');
            container.style.zIndex = "999";
            marker.src = Constants.markerIcons.user;
        }
        else{
            marker.src = Constants.markerIcons.ssn;
        }

        container.setAttribute("title", this.title);

        container.appendChild(marker);

        let content = this.get('content');
        let elem;
        if (content.tag === 'img'){
            elem = document.createElement('img');
            elem.src = content.value;
        }
        else{
            elem = document.createElement('div');
            elem.innerText = content.value;
        }

        elem.classList.add(content.class);
        container.appendChild(elem);

        container.style.position = 'absolute';

        this.set('container', container);

        this.getPanes().overlayMouseTarget.appendChild(container)

    }

    onRemove(){

        this.get('container').parentNode.removeChild(this.get('container'));
        this.set('container', null);
    }

    draw(){
        let pos = this.getProjection().fromLatLngToDivPixel(this.get('position'));

        let left = (pos.x - 42) + 'px';
        let top = (pos.y - 100) + 'px';

        if (!this.isUser) {
            left = (pos.x - 16) + 'px';
            top = (pos.y - 56) + 'px';
        };

        this.get('container').style.left = left;
        this.get('container').style.top = top;

    }
}

export default {
    name: 'Map',
    components: {
        MapHeader
    },
    data: function(){
        return {
            map: null,
            safelistAnsweredMarkers : {},
            userIndicator:{
                userMarker:null,
                userOverlay:null
            },
            safelistIndicators:{},
            userHomeDistance: null
        }
    },
    watch: {
        userLocationCoords : function(locationArr){

            if (locationArr && google){
                if (!this.map){
                    this.initMap();
                }
                else{
                    this.updateUserIndicator();
                }
            }
            this.getUserHomeDistance();
        },
        safelistAnswered : function(){

            if (this.map) this.updateSafelistIndicators();

        }

    },

    computed: {
        ...mapGetters({
            userLocationCoords: 'getUserLocationCoords',
            safelistAnswered : 'getSafelistAnswered',
            user: 'getUser',
            lastUserLocation: 'getLastUserLocation'
        })

    },

    methods: {
        ...mapActions({
            updateUserHomeDistance: 'updateUserHomeDistance'
        }),
        formatCoords(obj){
            return { lat: obj.lat, lng: obj.lon }
        },
        initMap(){

            const center = this.formatCoords(this.lastUserLocation);

            this.map = new google.maps.Map(this.$refs.map, {
                zoom:16,
                center,
                unitSystem: google.maps.UnitSystem.METRIC,
            });

            this.userIndicator = {}

            this.userIndicator.polyline = new google.maps.Polyline({
                path: this.userLocationCoords,
                strokeColor: 'red',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                map: this.map
            })

            let content;

            this.user.image
            ? content = {
                tag: 'img',
                class: 'overlay-img',
                value: this.user.image,
                title: this.user.full_name
            }
            : content = {
                tag: 'div',
                class: 'overlay-default',
                value: this.user.full_name[0],
                title: this.user.full_name
            }

            this.userIndicator.overlay = new CustomOverlay(this.map, center, content, true)
        },

        updateUserIndicator(){
            const center = this.formatCoords(this.lastUserLocation);

            if (this.userIndicator.polyline && this.userIndicator.overlay){

                this.map.setCenter(center);
                this.userIndicator.polyline.setPath(this.userLocationCoords);

                this.userIndicator.overlay.setValues({
                    position: new google.maps.LatLng(center)
                });
                this.userIndicator.overlay.draw();
            }
        },
        updateSafelistIndicators(){

            for(let ssn of this.safelistAnswered){

                if(ssn.response_type === Constants.responseType.canHelp){


                    const position = this.formatCoords(ssn.location);

                    const distanceFromUser = Utils.getMiles(google.maps.geometry.spherical.computeDistanceBetween(
                        new google.maps.LatLng(position),
                        new google.maps.LatLng(this.formatCoords(this.lastUserLocation))))

                    const ssnOverlayMap = distanceFromUser < 10 ? this.map : null

                    if( !this.safelistIndicators[ssn.id] ){
                        this.safelistIndicators[ssn.id] = {};


                        let content;
                        ssn.image

                        ? content = {
                            tag: 'img',
                            class: 'overlay-img',
                            value: ssn.image,
                            title: ssn.full_name
                        }
                        : content = {
                            tag: 'div',
                            class: 'overlay-default',
                            value: ssn.full_name[0],
                            title: ssn.full_name
                        }

                        this.safelistIndicators[ssn.id].overlay = new CustomOverlay(ssnOverlayMap, position, content);
                    }

                    else{

                        this.safelistIndicators[ssn.id].overlay.setMap(ssnOverlayMap)

                        this.safelistIndicators[ssn.id].overlay.setValues({
                            position: new google.maps.LatLng(position)
                        });

                    }
                }
                else{

                    if (this.safelistIndicators[ssn.id]){
                        this.safelistIndicators[ssn.id].overlay.setMap(null);
                    }
                }

            }
        },
        getUserHomeDistance(){


            if(this.user.home_address){

                if(!this.geocoder){

                    this.geocoder = new google.maps.Geocoder();
                }

                this.geocoder.geocode({'address': this.user.home_address}, (result, status) => {
                    if (status === 'OK'){

                        let userPos = new google.maps.LatLng(this.formatCoords(this.lastUserLocation));
                        let userHome =  result[0].geometry.location;
                        let userHomeDistance = Utils.getMiles(google.maps.geometry.spherical.computeDistanceBetween(userPos, userHome));

                        this.updateUserHomeDistance(userHomeDistance);
                    }
                    else {
                        console.error('geocoding service failed');
                        this.updateUserHomeDistance(null);
                    }
                })
            }
            else{
                this.updateUserHomeDistance(null);
            }
        }
    }


}
</script>
