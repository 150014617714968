<template>
    <div class="safelist">
        
        <div v-if="safelistAnswered.length > 0" class="safelist__answered">
            <h2>
                Responded
            </h2>
            
            <div class="safelist__answered--ssns">
        
                <SafelistIcon v-for="ssn in safelistCanHelp" v-bind:ssn="ssn"/>
                <SafelistIcon v-for="ssn in safelistCannotHelp" v-bind:ssn="ssn"/>
                
            </div>

        </div>
         
        <div class="safelist__unanswered">
            
            
            <h2 v-if="safelistAnswered.length == 0" class="safelist__title"> 
                Responding...
            </h2>

            <h2 v-if="safelistAnswered.length > 0 && safelistUnanswered.length > 0" class="safelist__title">
                Did not respond
            </h2> 
 
            <div class="safelist__unanswered--ssns">
                <SafelistIcon v-for="ssn in safelistUnanswered" v-bind:ssn="ssn"/>
            </div>
        </div>
            
    </div> 

</template>

<script>
import Constants from '../Lib/Constants';
import { mapGetters, mapActions } from 'vuex';
import SafelistIcon from './SafelistIcon.vue';

export default {
    name: 'Safelist',
    components: {
        SafelistIcon
    },
    computed: mapGetters({
        targetSsn: 'getTargetSsn',
        safelistAnswered: 'getSafelistAnswered',
        safelistCanHelp: 'getSafelistAnsweredCanHelp',
        safelistCannotHelp: 'getSafelistAnsweredCannotHelp',
        safelistUnanswered: 'getSafelistUnanswered'
        
    
    })
}

</script>