import * as types from '../mutation-types';

const state = {

    image:null,
    full_name: null,
    locationHistory:[],
    isLoading: null,
    user_id: null,
    home_address: null,
    home_distance: null
}

const mutations = {

    [types.loadUserStarted] (state){
        state.isLoading = true;
    },
    
    [types.loadUserFailed] (state){
        state.isLoading = false;
    },

    [types.loadUserSucceed] (state, {user}){

        state.image = user.image;
        state.full_name = user.full_name;
        state.isLoading = false;
        if (typeof user.home_address !== 'undefined') state.home_address = user.home_address;
    },

    [types.loadAlarmSucceed] (state, {alarm}){
        state.user_id = alarm.user_id;
    },

    [types.receiveUserTracking] (state, {locationHistory}){

        state.locationHistory =  [...Object.values(locationHistory)]
    
    },

    [types.updateUserHomeDistance] (state, {distance}){
        
        state.home_distance = distance;
    }

}
const actions = {
    updateUserHomeDistance: ({state, commit, rootState}, distance) => commit({
        type: types.updateUserHomeDistance,
        distance
    })
}
const getters = {
    getUser: state => state,
    getUserLocationCoords: state => state.locationHistory.map(coords => ({lat:coords.lat, lng:coords.lon})),
    getLastUserLocation: state => state.locationHistory[state.locationHistory.length-1]
}

export default {
    state,
    mutations, 
    actions,
    getters
}