var firebase = require('firebase/app');
require('firebase/database');
require('firebase/auth');

var http_request = require('request-promise');

//TODO put this somewhere that it can be rotated or configured
const alarm_panel_uid = 'd2a27917-e7b9-4873-a5bf-350b862edf8d';
var jwt = require('jsonwebtoken');


import Config from '../Lib/Config';
import Utils from '../Lib/Utils';
import Constants from '../Lib/Constants';

import geolocationManager from './geolocationManager';

import store from '../store';
import * as types from '../store/mutation-types';


//todo: fix error handling
class FirebaseManager{

    constructor(){

        this.alarmID = Utils.getParameterByName('alarm_id')
        this.safeListUID = Utils.getParameterByName('receive_safe_list_user_id')

        this.firebase = firebase.initializeApp(Config.firebase);
        this.database = this.firebase.database();

        var additionalClaims = {
          alarmPanel: true
        };

        //load inital info
        http_request('https://alert.katana.us/Application/getToken?panel_id='+alarm_panel_uid, {method: 'GET'})
        .then(token_response => this.firebase.auth().signInWithCustomToken(token_response))
        .then(() => this.database.ref('alarms/' + this.alarmID).once('value'))
        .then(data => {
            const alarm = data.val();
            console.log('alarm found');
            store.commit({
                type: types.loadAlarmSucceed,
                alarm
            });
            store.commit({type : types.loadUserStarted});

            const userID = alarm.user_id;
            return userID

        }, err => {
            console.log(err);
            throw(types.loadAlarmFailed);

        })

        .then( userID => this.database.ref('users/' + userID).once('value'))
        .then( userInfo => {
            const user = userInfo.val();
            store.commit({
                type: types.loadUserSucceed,
                user
            });

        }, err => {
            console.log(err);
            throw(types.loadUserFailed);

        })

        //setup change listeners from firebase
        .then(() =>{
            const responseID = store.state.alarm.alarm_response_id;
            this.database.ref('alarms_responses/' + responseID + '/safe_list_user_response').on('value',
                data => {

                    const responses = data.val();
                    store.commit({
                        type: types.receiveResponseUpdate,
                        responses
                    })
                },
                err => {
                    console.log(err);
                    throw(types.loadTrackingFailed);

                })
        })
        .then(() => {

            const trackingID = store.state.alarm.tracking_id;
            this.database.ref('tracking/' + trackingID + '/tracking_data/').on('value',
                data => {
                    const locationHistory = data.val();
                    store.commit({
                        type: types.receiveUserTracking,
                        locationHistory
                    })
                },
                err => {
                    console.log(err);
                    throw(types.loadTrackingFailed);

                })
        })
        .then(()=> {
            const userID = store.state.user.user_id;
            this.database.ref('users/' + userID + '/safe_list').on('value',
                data => {
                    const safe_list = data.val();
                    store.commit({
                        type: types.receiveSsnUpdate,
                        safe_list
                    })
                },
                err => {
                    console.log(err);
                    throw(types.loadTrackingFailed);

                })
        })
        .then(()=> {
            this.database.ref('alarms/' + this.alarmID).on('value',
                data => {
                    const alarm = data.val();

                    store.commit({
                        type: types.receiveAlarmUpdate,
                        alarm
                    });
                },
                err => {
                    console.log(err);
                    throw(types.loadTrackingFailed);

                })
        })

        .catch( err => {
          console.log(err);
          store.commit(types[err])

        })

        //setup change listeners from vuex
        store.subscribe( mutation => {

            if (mutation.type === types.sendResponse) {


                const responseID = store.state.alarm.alarm_response_id;

                this.database.ref('alarms_responses/' + responseID + '/safe_list_user_response/').once('value',

                    data => {

                        const alarmsResponses = data.val();
                        const ssnIndex = alarmsResponses.findIndex( ssn => ssn.safe_list_user_id == this.safeListUID);

                        this.database.ref('alarms_responses/' + responseID + '/safe_list_user_response/' + ssnIndex).update({
                            response_type: mutation.payload.response,
                            created_at: Date.now()
                        });

                    },

                    err => {
                        console.error('Get alarms_responses error', err);
                    });

            }

            if(mutation.type === types.updateSsnLocation){

                const userID = store.state.user.user_id;
                const ssnLocation = mutation.payload;

                if (userID){
                    this.database.ref('users/' + userID + '/safe_list/' + this.safeListUID).update({
                        location: {
                            ...ssnLocation,
                            updated_at: Date.now()
                        }
                    });
                }

            }


        })

    }


}

export default new FirebaseManager();
