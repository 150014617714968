import Vue from 'vue';
import Vuex from 'vuex';

import alarm from './modules/alarm';
import safelist from './modules/safelist';
import user from './modules/user';
import call from './modules/call';

Vue.use(Vuex);

const logger = store => {

    store.subscribe((mutation, state)=> {
        // console.log(mutation);
    })

}

export default new Vuex.Store({
    modules: {
        user,
        alarm,
        safelist,
        call
    },
    plugins : [logger]
})