import Vue from 'vue';
import App from './App.vue'
import store from './store'
import Utils from './Lib/Utils'
import Config from './Lib/Config';

import FirebaseManager from './Lib/firebaseManager';
import callManager from './Lib/callManager';
import geolocationManager from './Lib/geolocationManager';

let alarmId = Utils.getParameterByName("alarm_id");

new Vue({
    el: '#app',
    store,
    render: createElem => createElem(App)
});
