module.exports={
  "_args": [
    [
      "tough-cookie@2.3.4",
      "/Users/chris.jones/Projects/katana/katana-mobile-api"
    ]
  ],
  "_from": "tough-cookie@2.3.4",
  "_id": "tough-cookie@2.3.4",
  "_inBundle": false,
  "_integrity": "sha512-TZ6TTfI5NtZnuyy/Kecv+CnoROnyXn2DN97LontgQpCwsX2XyLYCC0ENhYkehSOwAp8rTQKc/NUIF7BkQ5rKLA==",
  "_location": "/tough-cookie",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "tough-cookie@2.3.4",
    "name": "tough-cookie",
    "escapedName": "tough-cookie",
    "rawSpec": "2.3.4",
    "saveSpec": null,
    "fetchSpec": "2.3.4"
  },
  "_requiredBy": [
    "/less/request",
    "/node-sass/request",
    "/request-promise"
  ],
  "_resolved": "https://registry.npmjs.org/tough-cookie/-/tough-cookie-2.3.4.tgz",
  "_spec": "2.3.4",
  "_where": "/Users/chris.jones/Projects/katana/katana-mobile-api",
  "author": {
    "name": "Jeremy Stashewsky",
    "email": "jstashewsky@salesforce.com"
  },
  "bugs": {
    "url": "https://github.com/salesforce/tough-cookie/issues"
  },
  "contributors": [
    {
      "name": "Alexander Savin"
    },
    {
      "name": "Ian Livingstone"
    },
    {
      "name": "Ivan Nikulin"
    },
    {
      "name": "Lalit Kapoor"
    },
    {
      "name": "Sam Thompson"
    },
    {
      "name": "Sebastian Mayr"
    }
  ],
  "dependencies": {
    "punycode": "^1.4.1"
  },
  "description": "RFC6265 Cookies and Cookie Jar for node.js",
  "devDependencies": {
    "async": "^1.4.2",
    "string.prototype.repeat": "^0.2.0",
    "vows": "^0.8.1"
  },
  "engines": {
    "node": ">=0.8"
  },
  "files": [
    "lib"
  ],
  "homepage": "https://github.com/salesforce/tough-cookie",
  "keywords": [
    "HTTP",
    "cookie",
    "cookies",
    "set-cookie",
    "cookiejar",
    "jar",
    "RFC6265",
    "RFC2965"
  ],
  "license": "BSD-3-Clause",
  "main": "./lib/cookie",
  "name": "tough-cookie",
  "repository": {
    "type": "git",
    "url": "git://github.com/salesforce/tough-cookie.git"
  },
  "scripts": {
    "suffixup": "curl -o public_suffix_list.dat https://publicsuffix.org/list/public_suffix_list.dat && ./generate-pubsuffix.js",
    "test": "vows test/*_test.js"
  },
  "version": "2.3.4"
}
