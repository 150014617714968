var _ = require('lodash');
var $ = require("jquery");

(function(global) {
    "use strict";

    var APIClient = function() {};

    APIClient.prototype.get = function(url, data, success, error) {

        var self = this;
        var headers = {};

        var getDataFormat = '';

        for (var key in data) {
          if(getDataFormat.length === 0){
            var value = key+"="+data[key];

            getDataFormat += (String(value));
          }
          else{
            var value1 = "&"+key+"="+data[key];
              getDataFormat += (String(value1));
          }
        }

        $.ajax({
            type: 'GET',
            crossDomain: true,
            url: url + '?' + getDataFormat,
            dataType: 'json',
            headers: headers,
            success: function(response) {

                if (_.isFunction(success)) {
                    success(response);
                }
            },
            error: function() {
                if (_.isFunction(error)) {
                    error();
                }
                console.log('API error', error);
            }
        });

    };

    APIClient.prototype.post = function(url, data, success, error) {

        var self = this;

        var headers = {};

        $.ajax({
            type: 'POST',
            crossDomain: true,
            url: url,
            data: data,
            dataType: 'json',
            headers: headers,
            success: function(response) {

                if (_.isFunction(success)) {
                    success(response);
                }
            },
            error: function() {
                if (_.isFunction(error)) {
                    error();
                }
                console.log('API error', error);
            }
        });

    };

    // returns instance
    module.exports = new APIClient();

})();
