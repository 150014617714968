<template>
    <div class="mapHeader">

        <div class="mapHeader__distance">
            <p v-if="user.home_address">Home: {{user.home_address}}</p>
            <p v-if="user.home_distance">({{formatHomeDistance(user.home_distance)}})</p>
        </div>
        
        <div class="mapHeader__age">
            <p>Approximate Location <br/>{{formatUserLocationAge(lastUserLocation)}}</p>
        </div>
        
    </div>
    
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MapHeader',
  computed: {
      ...mapGetters({
          user: 'getUser',
          lastUserLocation: 'getLastUserLocation'
      })
  },
  methods: {
      formatHomeDistance: function(distance){
          console.log(distance);
          return typeof distance === "number" 
          ? `${distance.toPrecision(3)} miles from home`
          : ''
      },

      formatUserLocationAge: function(location){

            if (!location){
                return;
            }
            let updatedAtLocation = location.created_at;
            let currentDateTime = Date.now();

            let difference = currentDateTime - updatedAtLocation;

            let differenceDate = new Date(difference);

            let days = Math.ceil(difference / (1000 * 3600 * 24)) - 1;
            let hours = differenceDate.getHours();
            let minutes = differenceDate.getMinutes();

            if (days < 0)
                days = 0;

            if (hours == 0)
                hours = 23;
            else
                hours = hours - 1;

            // if location is older than 30 min
            if (difference >= 60000 * 30)
                return "(" + days + " days/" + hours + " hours/" + minutes + " minutes old)";
            else
                return "";
      }

  }

}
</script>